import * as React from "react";
import UpdateCard from "./UpdateCard";
// import Pagination from "./Pagination/Pagination";
import blogImage from "../images/CrazyTech  Web Data/Crazy Tech Ai Banners/Blogs Banner.png"

function Blog(props) {
  return (
    <>
      	<img
				src={blogImage}
				class='d-block w-100 carousel-item-image'
				alt='...'
				style={{ height: '500px' }}
			/>
      <section className="projects">
        <div className="projects-section">
          <div className="section-content">
            <div className="content-title mt-5" >Latest Updates & News</div>
            <div className="content-dsc">
              Stay informed on the latest technological trends and development
              practices in the IT industry.
            </div>
          </div>

          <div className="section-card-two">
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702625445/Rectangle_1553_m0tnfe.png"
              title="Top 10 Global Software Development Companies"
              description="Where there are software needs, there are software solutions providers..."
              authorImage="https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              autherName="Stella Smith"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652824/Rectangle_1553_6_bfnx7s.png"
              title="AI Chat open Assistant Chat Comprehensive Guide"
              description="In the ever-evolving landscape of artificial intelligence and human-computer interaction..."
              authorImage="https://images.pexels.com/photos/1559486/pexels-photo-1559486.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              autherName="John Michael"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652823/Rectangle_1553_7_ivyu3w.png"
              title="Protecting your business with virtual desktop infrastructure!"
              description="Virtual Desktop Infrastructure (VDI) security encompasses the technologies..."
              authorImage="https://randomuser.me/api/portraits/men/22.jpg"
              autherName="Sam Martinez"
            />

            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652824/Rectangle_1553_8_yqx8te.png"
              title="Cracking the Major Software Development Failures!"
              description="In recent years, the need for software development has increased dramatically..."
              authorImage={
                "https://leadsoftwares.com/assets/review%203-CwkXzo1I.png"
              }
              autherName="Alex Johnson"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652822/Rectangle_1553_9_at3xun.png"
              title="What is the next big thing in software"
              description="In this era of digital and IT boom, it is essential that IT companies adopt new strategies..."
              authorImage="https://leadsoftwares.com/assets/review%201-6VjxUtWM.png"
              autherName="Morgan Davis"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652822/Rectangle_1553_10_ocjk1c.png"
              title="TOP TRENDS IN SOFTWARE DEVELOPMENT FOR 2024"
              description="Software development is a dynamic field that continuously adapts to emerging trends..."
              authorImage="https://leadsoftwares.com/assets/review%202-9Q4lGuv5.png"
              autherName="David Alexander"
            />

            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652822/Rectangle_1553_11_eyf6e9.png"
              title="The Role Of Artificial Intelligence In Ad-Tech!"
              description="Ad-Tech and Artificial Intelligence: Unlocking the Future of Advertising. Figure..."
              authorImage="https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-600nw-1714666150.jpg"
              autherName="Robert William"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652824/Rectangle_1553_12_iohctb.png"
              title="How AI Has Morphed the Tourism Industry!"
              description="Explore how AI has transformed the travel and tourism sector! Discover how artificial..."
              authorImage="https://img.freepik.com/free-photo/portrait-successful-business-man-talking-smartphone_158595-5374.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1707177600&semt=ais"
              autherName="Andrew Charles"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652819/Rectangle_1553_13_ldbwvt.png"
              title="Why Choose Managed IT Services Over Staff"
              description="Introduction Countless outsourcing models have emerged as software..."
              authorImage="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
              autherName="Thomas William"
            />

            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702625445/Rectangle_1553_m0tnfe.png"
              title="Top 10 Global Software Development Companies"
              description="Discover the game-changers of the software world in 2023. Invest in innovation..."
              authorImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYxsG3Ac8-CCLG3PzEvZXAfVoQxmjHleJqjg&s"
              autherName="Jennifer Lynn"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652819/Rectangle_1553_14_sgktim.png"
              title="Top 10 Global Software Development Companies"
              description="Where there are software needs, there are software solutions providers..."
              authorImage="https://static.boredpanda.com/blog/wp-content/uploads/2019/06/boss-criticizes-women-photo-sexual-harassment-5d09e795c4167__700.jpg"
              autherName="Emily Anne"
            />
            <UpdateCard
              image="https://res.cloudinary.com/dqg52thyo/image/upload/v1702652819/Rectangle_1553_15_g8k6fp.png"
              title="The Realm Of Insurance Automation: A Short Guide!"
              description="Leverage the essential steps in MedTech software development with our concise..."
              authorImage="https://i.pinimg.com/236x/fb/8d/2b/fb8d2be23fb260b3564a3d837e279fef.jpg"
              autherName="Jessica Marie"
            />
          </div>

          {/* <Pagination /> */}
        </div>
      </section>
    </>
  );
}

export default Blog;
