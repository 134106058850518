import { IconCheck } from '@tabler/icons-react'
import AOS from 'aos'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Colors from '../../constants/colors'
import MobileDevelopmentIcon from '../../images/CrazyTech  Web Data/SVG icons components/MobileDevelopmentIcon'
import './ourServices.css'
import WebDevelopmentIcon from '../../images/CrazyTech  Web Data/SVG icons components/WebDevelopmentIcon'
import SoftwareDevelopmentIcon from '../../images/CrazyTech  Web Data/SVG icons components/SoftwareDevelopmentIcon'
import CloudDevopsIcon from '../../images/CrazyTech  Web Data/SVG icons components/CloudDevopsIcon'
import GraphicDesignIcon from '../../images/CrazyTech  Web Data/SVG icons components/GraphicDesignIcon'
import QATestingIcon from '../../images/CrazyTech  Web Data/SVG icons components/QATestingIcon'

function Ourservices({ check }) {
	// const [hover, setHover] = useState(false)

	useEffect(() => {
		AOS.init()
	}, [])

	return (
		<>
			<section className='my-our-services'>
				<div className='container'>
					<div className='servies-main-title'>
						<h1 className='text-center'>Services We Offer</h1>
					</div>
					<div className='row'>
						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4'>
							<div className='services-cards'>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
									// onMouseEnter={setHover(true)}
								>
									<div className='card-image-logo'>
										{/* <img src="https://res.cloudinary.com/dqg52thyo/image/upload/v1701089525/Crazy%20Tech/developer_mode_nhpu7c.svg" alt="Developer Mode" /> */}
										{/* <MobileDevelopmentIcon height={'50px'}  /> */}

										{/* <img
											style={{
												color: 'green',
											}}
											src={mobileSvg}
											className='icon_svg'
											alt='Mobile Development'
											height={40}
										/> */}

										<MobileDevelopmentIcon
											height={40}
											fill={Colors.primaryColor}
										/>

										{/* width={45} height={45} */}
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Mobile App Development</h6>
										{/*<p className="card-description">*/}
										{/*  Turning Ideas into Experiences. Precision Code, Seamless Design. Your App, Elevated. Where Innovation Meets Excellence.*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Native App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Cross Platform App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Hybrid App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Finance and Banking App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											E-Commerce App Development
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4 '>
							<div className='services-cards '>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
								>
									<div className='card-image-logo'>
										{/* <img
											src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703501860/Crazy%20Tech/Development-01_nggzid.png'
											alt='Developer Mode'
											width={45}
											height={45}
										/> */}
										<WebDevelopmentIcon
											height={40}
											fill={Colors.primaryColor}
										/>
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Web App Development</h6>
										{/*<p className="card-description">*/}
										{/*Transforming ideas into seamless digital experiences—Crafting web apps that captivate, innovate, and elevate user engagement*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Custom Web App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											E-Commerce Web Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Content Management System Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Real Time Web Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											WordPress Development
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4 '>
							<div className='services-cards '>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
								>
									<div className='card-image-logo'>
										{/* <img
											src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703504576/Crazy%20Tech/Custom_Software_Development-01_hb4p6e.png'
											alt='Developer Mode'
											width={45}
											height={45}
										/> */}
										<SoftwareDevelopmentIcon
											height={40}
											fill={Colors.primaryColor}
										/>
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Custom Software Development</h6>
										{/*<p className="card-description">*/}
										{/*Innovating bespoke solutions—Crafting tailored apps to elevate user experiences, one line of code at a time*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Mobile App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Web App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Enterprise App Development
										</div>

										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Cloud-Based App Development
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Custom CRM Development
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4 '>
							<div className='services-cards '>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
								>
									<div className='card-image-logo'>
										{/* <img
											src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703581093/Deveops_gzzion.png'
											alt='Developer Mode'
											width={45}
											height={30}
											style={{ verticalAlign: 'text-top' }}
										/> */}
										<CloudDevopsIcon height={40} fill={Colors.primaryColor} />
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Cloud / DevOps Development</h6>
										{/*<p className="card-description">*/}
										{/*Unleashing the power of cloud technology—Seamless DevOps solutions for agility, scalability, and unparalleled efficiency.*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Cloud Strategy and Consulting
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Cloud Migration Services
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											DevOps Automation
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Monitoring and Logging
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Cost Optimization
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4 '>
							<div className='services-cards '>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
								>
									<div
										className='card-image-logo'
										style={{ padding: '7px 10px' }}
									>
										{/* <img
											src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703581093/Graphic_Design-01_kq4bqa.png'
											alt='Developer Mode'
											width={45}
											height={45}
                    /> */}
										<GraphicDesignIcon height={40} fill={Colors.primaryColor} />
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Graphic Design</h6>
										{/*<p className="card-description">*/}
										{/*Where imagination meets design—Crafting visual stories that inspire, captivate, and leave a lasting impression on every canvas.*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											UI/UX Design
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Custom Illustration
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Digital Design
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Logo Design
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Social Media Graphic
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='px-3 col-md-4 col-sm-6 col-12 mt-4 '>
							<div className='services-cards '>
								<div
									className='card p-4'
									data-aos='fade-right'
									data-aos-duration='900'
								>
									<div className='card-image-logo'>
										{/* <img
											src='https://res.cloudinary.com/dqg52thyo/image/upload/v1703502002/Crazy%20Tech/SQA-01_mptper.png'
											alt='Developer Mode'
											width={45}
											height={45}
										/> */}
										<QATestingIcon height={40} fill={Colors.primaryColor} />
									</div>
									<div className='services-card-title'>
										<h6 className='mt-4'>Software QA & Testing</h6>
										{/*<p className="card-description">*/}
										{/*Assuring perfection in every code—Where quality meets precision for flawless software experiences and reliability.*/}
										{/*</p>*/}
									</div>
									<div className='services-card-list'>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Functional testing
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Regression Testing
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Performance Testing
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											API Testing
										</div>
										<div className='check-icone services-list'>
											<IconCheck
												stroke={2}
												size={20}
												clase='checkIcon'
												style={{ marginRight: '10px' }}
											/>
											Accessibility Testing
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='services-nav-btn text-center mt-4'>
						<Link to='/services' className='text-decoration-none'>
							<button type='button' className='view-button'>
								<div className='button-title'>View All Services</div>
							</button>
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}

export default Ourservices
