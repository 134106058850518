import React from 'react'

const CommunicationToolIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m336.58,146.13c5.95,7.12,11.49,2.86,17.09.37,5.58-2.48,11.19-4.9,16.72-7.5,3.77-1.77,6.91-1.32,9.88,1.68,9.77,9.87,19.58,19.71,29.47,29.46,3.18,3.13,3.53,6.37,1.7,10.31-3.48,7.49-6.94,14.99-9.99,22.65-.76,1.91-.73,4.89.26,6.64,7.57,13.46,13.66,27.51,17.44,42.49.78,3.11,2.3,4.67,5.25,5.72,7.77,2.77,15.39,5.95,23.13,8.81,3.97,1.47,5.7,4.19,5.69,8.3-.03,13.89-.01,27.78-.01,41.68,0,3.9-1.76,6.41-5.44,7.98-5.68,2.43-10.72,3.45-17.29,1.24-20.68-6.94-43.54,1.8-55.58,20.02-12.04,18.21-10.77,42.13,3.13,59.17.92,1.13,1.88,2.22,2.5,2.94-7.46,5.67-14.83,11.12-22.02,16.81-2.3,1.83-4.66,4-6.02,6.53-4.07,7.56-10.97,11.56-18.28,14.7-11.8,5.07-23.82,9.66-35.91,14-3.01,1.08-4.62,2.41-5.67,5.31-2.74,7.61-5.89,15.07-8.64,22.67-1.58,4.36-4.39,6.14-9,6.09-13.56-.14-27.12-.13-40.67,0-4.42.04-7.12-1.76-8.64-5.88-2.79-7.59-5.88-15.08-8.6-22.69-1.1-3.09-2.93-4.38-6.05-5.57-13.83-5.31-27.45-11.15-41.1-16.93-1.59-.68-3.27-1.88-4.19-3.31-8.67-13.36-20.14-23.54-34.83-30.72.89-1.21,1.58-2.24,2.37-3.18,15-17.83,16.09-42.92,2.64-61.54-13.5-18.69-37.51-25.59-59.17-16.99-9.01,3.58-20.23-3.96-20.23-13.59,0-12.4.23-24.81-.1-37.21-.16-5.78,2.17-8.8,7.45-10.6,7.34-2.5,14.6-5.27,21.71-8.36,1.91-.83,4.02-2.98,4.56-4.94,4.18-15.05,10.06-29.33,17.8-42.9.99-1.74.97-4.75.21-6.69-2.9-7.37-6.17-14.6-9.58-21.75-2.2-4.63-1.82-8.26,2.04-11.97,9.53-9.17,18.85-18.57,28.07-28.05,3.36-3.45,6.7-4.27,11.07-2.13,5.33,2.61,10.82,4.9,16.26,7.31q8.86,3.93,17.31.52c.24,1.96.33,3.9.73,5.76,2.31,10.57,10.9,18,21.92,18.05,39.19.17,78.37.17,117.56,0,11.77-.05,20.22-7.81,22.23-19.38.25-1.45.46-2.91.84-5.35Zm-81.76,266.69c64.63-.05,117.07-52.41,117.12-116.95.05-64.49-52.47-117.16-116.91-117.24-64.81-.08-117.27,52.41-117.22,117.28.05,64.67,52.38,116.95,117.01,116.91Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m95.18,476.95c-18.33,0-36.66.02-54.98,0-8.78-.01-11.28-3.43-8.82-11.86,8.26-28.33,34.89-48.21,64.36-48.06,29.93.15,56.29,20.19,64.25,48.86,2.1,7.56-.45,11.03-8.34,11.06-18.82.06-37.65.02-56.47.02Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m416.1,476.95c-18.33,0-36.66.02-54.99,0-8.93-.01-11.39-3.26-8.97-11.73,8.06-28.23,34.7-48.21,64.27-48.19,29.79.02,56.45,20.17,64.41,48.7,2.14,7.68-.56,11.2-8.74,11.22-18.66.04-37.32.01-55.98.01Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m254.57,155.07c-18.5,0-36.99.02-55.49-.01-8.52-.02-10.91-3.3-8.56-11.57,8.07-28.36,35.05-48.45,64.77-48.21,29.82.24,56.5,20.68,64.1,49.12,1.9,7.12-.77,10.63-8.34,10.65-18.83.05-37.65.02-56.48.02Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m254.99,85.46c-18.97.08-33.98-14.89-33.95-33.86.03-18.68,14.99-33.74,33.59-33.81,18.78-.07,34.1,15.19,34.06,33.95-.03,18.63-15.04,33.64-33.7,33.72Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m382.55,373.52c-.07-18.57,14.93-33.81,33.5-34.03,18.6-.22,34.2,15.2,34.27,33.86.07,18.67-15.41,34.1-34.09,33.97-18.63-.12-33.6-15.16-33.67-33.8Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m129.38,374.17c-.5,18.74-15.79,33.5-34.3,33.12-18.87-.39-33.91-15.95-33.43-34.6.49-18.73,16.4-33.85,34.91-33.18,18.68.68,33.32,16.13,32.82,34.66Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m330.71,295.73c-12.02-10.13-23.61-19.89-35.2-29.67-1.26-1.06-2.56-2.1-3.74-3.25-3.5-3.44-3.85-7.66-.98-11.07,2.78-3.3,7.34-3.82,11.07-.87,5.05,3.99,9.91,8.23,14.83,12.37,9.85,8.3,19.73,16.56,29.53,24.92,6.24,5.32,6.3,9.9.18,15.08-14.36,12.17-28.81,24.24-43.17,36.41-3.36,2.85-6.86,4.06-10.81,1.56-4.86-3.08-4.78-8.97.3-13.33,11.42-9.77,22.95-19.41,34.43-29.1,1.01-.85,1.99-1.72,3.55-3.07Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m179.01,295.88c12.32,10.4,24.18,20.42,36.05,30.44.38.32.77.63,1.15.95,4.74,4.03,5.74,8.65,2.7,12.42-3.15,3.89-8.03,3.74-13.03-.46-8.86-7.45-17.68-14.92-26.53-22.38-5.69-4.79-11.45-9.5-17.06-14.38-4.82-4.19-4.97-9.07-.22-13.13,14.97-12.76,30.02-25.43,45.12-38.03,4.01-3.35,8.49-3.06,11.49.3,3.07,3.44,2.5,8.16-1.43,11.81-.73.67-1.5,1.29-2.26,1.93-11,9.26-22.01,18.52-33,27.78-.86.73-1.66,1.53-2.96,2.74Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m276.93,348.19c-.31,4.46-2.19,7.35-6.33,8.14-4.3.82-7.6-.89-8.98-5.09-1.64-5.01-2.89-10.14-4.26-15.24-7.89-29.47-15.85-58.92-23.52-88.44-.76-2.91-.66-6.85.78-9.27,1.13-1.89,5.31-3.41,7.52-2.82,2.46.66,5.33,3.56,6.1,6.08,3.75,12.29,6.88,24.78,10.2,37.2,5.84,21.82,11.67,43.65,17.5,65.47.38,1.43.71,2.87.98,3.97Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default CommunicationToolIcon
