import React from 'react'

const APIDevelopmentIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m493.42,329.68H18.49V84.32h474.93v245.35Zm-307.4-43.87c1.79,2.01,3.62,4.28,5.67,6.33,5.59,5.58,9.36,5.71,14.8.14,2.48-2.54,5.01-3.44,8.53-3.43,83.52.09,167.05.07,250.57.07,9.41,0,11.8-2.39,11.8-11.78,0-43.9,0-87.79,0-131.69,0-10.12-2.19-12.32-12.28-12.32-76.86,0-153.72-.27-230.58.28-11.67.08-20.91-1.94-27.75-11.62-.39-.55-.94-.99-1.45-1.44-4.23-3.7-8.27-3.85-12.25.07-2.65,2.6-4.75,5.76-7.1,8.66-4.48-2.83-7.59-4.98-11.01-5.68-7.74-1.58-10.24-5.67-9.34-13.48.75-6.52-2.91-9.53-9.67-9.58-7.69-.06-15.38.13-23.06-.05-12.34-.29-17.26-.55-16.37,16.04.08,1.41-.64,3.74-1.64,4.19-5.85,2.64-11.89,4.84-18.54,7.46-2.26-2.48-4.51-5.31-7.13-7.74-3.91-3.63-8.03-3.71-11.85-.02-7.74,7.47-15.36,15.08-22.82,22.83-3.84,3.99-3.64,8.12.37,12.21,2.49,2.53,5.42,4.65,7.82,6.68-2.13,4.65-3.81,7.99-5.22,11.44-3.59,8.76-3.55,8.78-13,8.95-6.97.12-9.84,3-9.89,10.08-.05,7.51.11,15.03-.04,22.55-.24,12.08-1.09,17.68,16.26,16.69,1.39-.08,3.62,1.12,4.17,2.32,2.55,5.53,4.65,11.28,7.35,18.05-2.28,1.99-5.17,4.15-7.63,6.73-3.9,4.07-3.99,8.26-.14,12.24,7.37,7.6,14.87,15.09,22.48,22.45,4.18,4.04,8.35,3.93,12.6-.29,2.52-2.5,4.52-5.52,6.38-7.83,4.82,2.24,7.93,4.43,11.34,5.11,7.76,1.55,10.24,5.67,9.39,13.44-.72,6.58,2.88,9.6,9.6,9.66,7.86.07,15.71.02,23.57.02,14.89,0,15.7-.79,15.85-15.43,0-.34-.14-.78.02-1.01.98-1.45,1.73-3.51,3.12-4.17,5.22-2.47,10.65-4.48,17.08-7.09Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m493.78,67.34H18.56c-.13-.94-.33-1.73-.33-2.53-.02-12.29-.07-24.59,0-36.88.04-6.44,3.02-9.41,9.33-9.59,1.19-.03,2.39,0,3.59,0,149.93,0,299.85,0,449.78,0,10.84,0,12.84,2,12.85,12.78,0,11.92,0,23.85,0,36.24Zm-253.85-32.52v15.93h32.05v-15.93h-32.05Zm-17.03,16.05v-15.8h-15.71v15.8h15.71Zm66-.07h15.93v-15.65h-15.93v15.65Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m18.21,346.49h475.57c0,2.23,0,4.37,0,6.51,0,10.93.05,21.86-.02,32.78-.04,6.67-3.05,9.58-9.75,9.71-1.02.02-2.05,0-3.07,0-149.92,0-299.85,0-449.77,0-11.07,0-12.95-1.92-12.95-13.18,0-11.76,0-23.53,0-35.83Zm81.9,16.53H34.98v15.69h65.13v-15.69Zm32.65-.02h-15.83v15.74h15.83v-15.74Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m298.85,412.12c4.05,16.25,8.05,32.27,12.15,48.68h-109.97c4.06-16.29,8.07-32.41,12.12-48.68h85.7Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m381.89,493.68h-251.87c2.29-4.51,3.75-9.08,6.62-12.43,1.87-2.18,5.81-3.57,8.84-3.6,30.73-.26,61.47-.16,92.21-.16,42.01,0,84.01.18,126.02-.17,7.64-.06,12.43,2.17,14.89,9.39.75,2.21,1.99,4.26,3.29,6.97Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m227.21,272.16c8.57-10.93-2.39-14.81-6.75-21.11,2.31-5.9,4.51-11.95,7.13-17.8.45-1,2.78-1.73,4.18-1.65,16.7.93,16.34-4.24,16.06-16.36-.18-7.68.01-15.37-.05-23.05-.06-6.78-3.05-10.43-9.56-9.69-7.78.89-11.92-1.53-13.49-9.32-.69-3.41-2.86-6.53-4.44-9.97,3.62-4.21,7.34-8.54,11.65-13.54h228.64v122.48h-233.37Zm110.72-24.49c-.17-13.07,1.25-25.74-3.9-38.1-4.11-9.88-6.92-20.31-10.35-30.48-3.17-9.4-8.41-13.14-18.26-13.17-10.29-.03-15.52,3.62-18.75,13.2-4.3,12.76-9.46,25.32-12.49,38.37-2.26,9.71-1.72,20.06-2.43,30.18h17.1v-16.14c5.81,0,11.07,0,16.34,0s10.77,0,16.51,0v16.15h16.22Zm24.54-24.36c7.37,0,14,.09,20.63-.02,16.12-.27,28.67-12.89,28.66-28.7-.01-15.81-12.58-28.41-28.7-28.64-8.71-.12-17.42-.05-26.13-.02-8.03.02-10.73,2.71-10.74,10.76-.02,22.2-.01,44.4,0,66.61,0,1.47.19,2.93.28,4.37h15.99v-24.35Zm73.55,24.38v-81.35h-15.77v81.35h15.77Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m72.22,263.47c1.53-1.32,3.82-3.12,5.89-5.14,3.41-3.32,3.29-6.97,1.37-11.21-3.71-8.18-7.19-16.48-10.34-24.89-1.72-4.57-4.31-7.21-9.32-7.12-2.71.04-5.42,0-8.47,0v-16.4c2.93,0,5.79,0,8.64,0,4.73.01,7.35-2.41,9.02-6.81,3.26-8.55,6.78-17.01,10.54-25.36,1.92-4.27,1.9-7.91-1.56-11.17-2.11-1.98-4.43-3.75-6.75-5.69,4.51-4.47,8.25-8.17,12.88-12.76,1.94,2.36,3.74,4.87,5.87,7.05,3.16,3.24,6.73,3.11,10.78,1.31,8.36-3.73,16.81-7.28,25.37-10.53,4.38-1.67,6.87-4.23,6.87-8.98,0-2.86,0-5.71,0-8.8h16.4c0,2.84.04,5.67,0,8.49-.1,4.99,2.46,7.65,7.05,9.37,8.41,3.15,16.73,6.59,24.89,10.32,4.46,2.04,8.19,2.03,11.59-1.62,1.86-2,3.58-4.12,5.64-6.5,4.07,4.52,7.56,8.39,11.92,13.23-1.62,1.36-4.02,3.19-6.2,5.26-3.27,3.11-3.38,6.65-1.58,10.73,3.77,8.53,7.4,17.13,10.75,25.83,1.61,4.18,4.13,6.55,8.63,6.62,2.88.04,5.76,0,8.97,0v16.4c-2.96,0-5.81,0-8.67,0-4.74-.02-7.34,2.41-9.01,6.81-3.25,8.56-6.78,17.02-10.54,25.37-1.92,4.27-1.91,7.91,1.56,11.16,2.11,1.98,4.44,3.74,6.76,5.68-4.53,4.49-8.27,8.18-12.84,12.71-1.95-2.34-3.77-4.83-5.9-7.01-3.16-3.22-6.72-3.12-10.78-1.31-8.36,3.74-16.8,7.29-25.36,10.54-4.38,1.66-6.89,4.2-6.88,8.96,0,2.86,0,5.72,0,8.82h-16.4c0-2.8-.04-5.63,0-8.46.08-4.97-2.42-7.66-7.03-9.39-8.41-3.15-16.72-6.61-24.89-10.33-4.45-2.03-8.18-2.08-11.59,1.6-1.85,2-3.6,4.11-5.68,6.5-3.97-4.55-7.39-8.47-11.6-13.31Zm126.36-56.47c.04-31.61-25.69-57.48-57.16-57.47-31.87.01-57.57,25.7-57.61,57.57-.04,31.22,25.73,57.11,56.93,57.2,31.95.09,57.8-25.52,57.84-57.3Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m292.09,214.93c3.46-10.44,6.75-20.61,10.26-30.69.31-.88,1.97-1.7,3.06-1.78.85-.06,2.33.88,2.61,1.69,3.49,10.12,6.8,20.29,10.27,30.78h-26.21Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m362.69,206.81v-24.13c7.81,0,15.6-.54,23.26.18,5.75.54,9.65,6.53,9.4,12.26-.26,5.94-4.84,11.24-10.9,11.59-7.09.41-14.21.1-21.76.1Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m141.01,247.89c-22.59-.09-40.92-18.65-40.78-41.31.13-22.58,18.75-40.84,41.43-40.65,22.38.19,40.55,18.6,40.53,41.04-.03,22.62-18.53,41-41.17,40.92Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default APIDevelopmentIcon
