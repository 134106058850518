import React from 'react'

const DataAnalyticsIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m490.6,79.63c-.53,1.55-1.13,3.08-1.59,4.65-6.85,23.47-32.74,35.03-54.59,24.2-2.66-1.32-4.5-1.34-7.06.2-21.31,12.82-42.69,25.51-64.11,38.14-2.23,1.32-3,2.72-3.04,5.39-.28,19.24-14.41,35.41-33.08,38.17-19,2.81-36.71-8.18-43.1-26.48-.7-2.01-2.48-4.22-4.36-5.16-19.59-9.92-39.32-19.56-58.95-29.4-2.27-1.14-3.63-.86-5.62.69-12.11,9.48-25.46,11.09-39.6,5.27-2.47-1.01-4.02-.87-6.11.71-23.07,17.33-46.19,34.58-69.38,51.75-2.38,1.76-2.58,3.29-1.91,6.09,3.9,16.2-.34,30.17-13.41,40.53-12.89,10.22-27.49,11.62-42.1,4.04-14.8-7.67-21.87-20.55-21.04-37.19,1.34-26.73,29.89-44.08,54.28-33.03,3.53,1.6,5.64,1.06,8.46-1.07,22.19-16.71,44.47-33.32,66.8-49.84,2.51-1.86,3.41-3.31,2.4-6.68-6.37-21.36,6.09-43.31,27.47-48.93,21.31-5.61,42.95,7.52,47.91,29.26.8,3.51.47,7.27.9,10.89.15,1.28.66,3.15,1.57,3.61,18.35,9.27,36.79,18.37,55.51,27.65,6.75-11.29,16.34-18.34,29.24-20.1,12.94-1.76,23.98,2.7,33.44,11.71,21.75-12.93,43.34-25.77,64.92-38.6-4.66-17.12-1.03-31.71,12.48-42.84,22.21-18.29,55.54-6.9,62.58,21.05.29,1.15.72,2.26,1.08,3.4v11.91Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m490.6,468.18c-3.61,7.27-9.44,9.67-17.48,9.41-15.1-.48-30.23-.12-45.34-.14-10.55-.02-15.29-4.75-15.29-15.41-.02-85.35-.02-170.69,0-256.04,0-10.27,4.89-15.2,15.13-15.24,15.27-.06,30.54.2,45.8-.11,8-.16,13.95,2.29,17.17,9.94v267.59Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m151.67,347.24c0-37.87,0-75.73,0-113.6,0-12.14,4.47-16.66,16.46-16.66,15.42,0,30.84-.01,46.26,0,10.82.01,15.55,4.73,15.57,15.69.04,29.93.01,59.85.01,89.78,0,46.26,0,92.53.01,138.79,0,4.52-.07,9.27-3.88,12.19-2.61,2-6.19,3.77-9.37,3.83-17.25.37-34.5.26-51.76.14-8.18-.06-13.21-5.53-13.28-14.28-.1-12.83-.03-25.65-.03-38.48,0-25.8,0-51.61,0-77.41Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m282.03,372.82c0-29,0-57.99,0-86.99,0-12.39,4.38-16.73,16.83-16.73,15.41,0,30.83-.03,46.24.01,10.26.03,15.22,4.93,15.22,15.14.03,59.36.03,118.73,0,178.09,0,10.21-4.99,15.09-15.24,15.11-15.87.03-31.74.02-47.61,0-10.54-.01-15.41-4.78-15.42-15.36-.05-29.76-.02-59.52-.02-89.27Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m99.53,399.29c0,20.91.02,41.82,0,62.73-.02,10.69-4.71,15.42-15.26,15.43-16.03.02-32.05.03-48.08,0-9.64-.02-14.75-5.05-14.75-14.62-.03-42.28-.03-84.56,0-126.84,0-9.63,4.95-14.72,14.64-14.79,16.33-.12,32.66-.13,49,0,9.71.08,14.43,5.04,14.46,14.89.05,21.06.02,42.13.02,63.19Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default DataAnalyticsIcon
