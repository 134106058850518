import React from 'react'

const ProductEngineeringIcon = ({height, fill}) => {
  return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
                height={height}
                fill={fill}
			>
				<path
					d='m24.82,207.74c1.09-2.54,1.73-4.59,2.78-6.39,4.43-7.57,8.99-15.07,13.5-22.59,3.93-6.55.3-15.5-7-17.32-8.5-2.12-17.01-4.23-25.5-6.39C2.05,153.38.07,150.85.03,144.06c-.04-5.55-.04-11.11,0-16.67.04-6.86,1.98-9.4,8.49-11.04,8.85-2.23,17.71-4.36,26.56-6.58,5.81-1.46,9.72-10.98,6.63-16.17-4.58-7.69-9.22-15.34-13.8-23.04-3.78-6.35-3.36-9.3,1.97-14.66,3.54-3.56,7.1-7.09,10.65-10.63,5.71-5.68,8.49-6.02,15.57-1.8,7.53,4.5,15.02,9.07,22.56,13.56,4.88,2.9,14.45-1.04,15.84-6.52,2.25-8.84,4.39-17.71,6.62-26.56,1.69-6.73,4.11-8.68,10.84-8.73,5.91-.04,11.83.07,17.74-.03,5.52-.09,8.71,2.64,10,7.83,2.25,9.03,4.45,18.06,6.71,27.09,1.47,5.87,10.88,9.92,16.04,6.86,7.86-4.65,15.66-9.4,23.5-14.07,5.76-3.43,9.05-3.04,13.85,1.69,4.09,4.02,8.15,8.07,12.15,12.18,4.23,4.35,4.7,7.8,1.64,13.01-4.63,7.87-9.4,15.65-14.09,23.49-3.48,5.83.44,14.94,7.11,16.6,8.68,2.16,17.36,4.31,26.03,6.51,6.25,1.59,8.28,4.19,8.34,10.64.05,5.73.05,11.47,0,17.2-.05,6.7-2.03,9.15-8.74,10.85-8.85,2.24-17.71,4.4-26.55,6.65-5.48,1.4-9.41,10.93-6.52,15.82,4.65,7.85,9.41,15.65,14.06,23.5,3.49,5.89,3.11,9.11-1.57,13.87-4.02,4.09-8.07,8.15-12.17,12.16-4.52,4.41-7.91,4.77-13.45,1.47-8-4.76-15.95-9.61-23.97-14.34-4.52-2.67-14.23,1.36-15.52,6.43-2.3,9.01-4.45,18.07-6.73,27.08-1.59,6.31-4.19,8.37-10.56,8.43-5.91.06-11.83.07-17.74,0-6.07-.07-8.77-2.18-10.28-8.11-2.3-9.01-4.45-18.07-6.73-27.09-1.4-5.57-10.87-9.57-15.77-6.66-8.01,4.75-15.94,9.62-23.96,14.35-5.5,3.24-8.84,2.77-13.42-1.73-4.22-4.14-8.51-8.23-12.5-12.59-1.71-1.87-2.72-4.38-4.05-6.6Zm95.58-20.43c28.44.05,51.68-23.11,51.72-51.53.04-28.45-23.09-51.67-51.52-51.72-28.44-.05-51.68,23.1-51.72,51.51-.05,28.42,23.12,51.68,51.52,51.73Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m241.32,289.12c25.36-14.78,50.09-29.2,74.82-43.61,11.91-6.94,23.85-13.82,35.72-20.82,6.28-3.71,12.48-3.91,18.8-.23,36.34,21.17,72.69,42.33,109.03,63.5.45.26.83.67,1.63,1.35-1.43.96-2.67,1.89-3.99,2.66-37.26,21.73-74.53,43.44-111.79,65.18-2.35,1.37-4.2,2.33-7.23.55-37.95-22.31-76.04-44.41-114.08-66.57-.75-.44-1.44-.98-2.91-2Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m481.95,308.22c0,2.19,0,3.75,0,5.3,0,34.4-.06,68.8.05,103.2.02,7.7-2.93,13.41-9.49,17.43-33.1,20.32-66.17,40.7-99.26,61.04-.73.45-1.53.8-2.88,1.5-.12-1.78-.28-3.1-.28-4.42-.01-38.16,0-76.32-.04-114.48,0-2.84.34-4.86,3.25-6.55,35.15-20.37,70.2-40.91,105.28-61.4.88-.51,1.84-.89,3.38-1.62Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m352.42,496.81c-15.59-9.58-30.67-18.83-45.73-28.09-18.45-11.35-36.84-22.8-55.38-34-7.14-4.31-10.43-10.18-10.39-18.57.17-34.22.07-68.45.07-102.67v-5.61c3.48,2.01,6.22,3.58,8.95,5.17,32.92,19.23,65.82,38.48,98.76,57.69,2.37,1.38,4.28,2.52,4.26,5.97-.15,39.42-.11,78.84-.13,118.26,0,.35-.15.69-.41,1.85Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m223.4,393.84c-34.83,0-69.22.1-103.6-.07-8.66-.04-16.26-7.04-16.33-15.55-.28-34.72-.11-69.45-.11-104.5h34.19v85.47h85.85v34.65Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m378.68,204.29h-34.26v-51.13h-85.85v-34.43c1.78-.08,3.51-.23,5.25-.23,31.71-.01,63.42-.02,95.12,0,13.11,0,19.73,6.68,19.74,19.91.02,21.82,0,43.65,0,65.89Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m154.9,135.64c.02,19.01-15.48,34.5-34.49,34.46-18.98-.04-34.41-15.61-34.32-34.63.09-18.76,15.46-34.11,34.22-34.18,19.04-.08,34.57,15.34,34.59,34.36Z'
					style={{fill: '#c0ff00;'}}
				/>
			</svg>
		</>
	)
}

export default ProductEngineeringIcon