import React from 'react'

const QATestingIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m335.96,398.27c0-1.95,0-3.9,0-5.85.05-48.29.06-96.59.16-144.88.03-13.04,11.37-21.15,22.36-16.03,6.95,3.24,9.43,9.15,9.42,16.52-.05,20.77-.02,41.54-.02,62.32,0,2.05,0,4.11,0,7.94,2.51-1.59,4.27-2.21,5.28-3.44,5.2-6.37,11.43-8.67,18.23-6.42,6.89,2.28,10.89,8.22,11.03,16.45.04,2.39,0,4.78,0,8.78,2.49-1.64,4.17-2.27,5.15-3.46,5.46-6.64,11.69-8.92,18.63-6.54,6.71,2.3,10.6,8.38,10.66,16.7.02,2.23,0,4.46,0,8.4,2.52-1.69,4.31-2.39,5.36-3.68,5.13-6.3,11.48-8.56,18.27-6.22,6.89,2.38,10.76,7.96,10.76,15.99,0,25.1.14,50.2-.24,75.29-.12,7.91-1.49,15.82-2.63,23.68-1.73,12.02-8.1,21.37-18.11,27.88-27.33,17.76-56.52,21.44-87.13,9.86-12.3-4.65-21.8-13.18-28.89-24.34-13.35-21.02-26.76-41.99-40.2-62.95-5.04-7.86-10.3-15.59-15.3-23.48-6.03-9.51-3.87-19.89,5.26-26.16,5.86-4.02,16.74-3.24,21.39,2.31,4.53,5.4,8.28,11.48,12.23,17.35,4.64,6.89,9.11,13.88,13.7,20.8.87,1.31,1.96,2.47,2.94,3.69.55-.16,1.1-.33,1.65-.49Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m367.49,216.58c-9.2-4.18-18.12-4.37-27.02-2.23-11.68,2.81-18.86,12.16-18.92,24.14-.1,18.35-.07,36.71-.09,55.06,0,1.87,0,3.74,0,6.79-6.99-3.58-13.3-6.62-19.41-10.03-3.15-1.76-5.68-1.86-9.03-.14-5.51,2.83-11.33,5.14-17.21,7.11-3,1.01-4.5,2.47-5.35,5.46-1.71,5.98-3.61,11.92-5.91,17.69-.62,1.55-2.95,3.35-4.57,3.42-8.47.34-16.97.32-25.45.01-1.51-.05-3.72-1.73-4.28-3.17-2.36-6.12-4.03-12.5-6.38-18.63-.68-1.77-2.51-3.57-4.26-4.39-6.25-2.9-12.64-5.54-19.11-7.93-1.81-.67-4.35-.77-6.06-.01-5.84,2.6-11.5,5.6-17.13,8.64-2.47,1.33-4.21,1.4-6.32-.82-5.84-6.15-12.05-11.96-17.82-18.17-1.01-1.08-1.37-3.79-.76-5.18,2.6-5.84,5.93-11.35,8.46-17.21.86-1.99.74-4.88,0-6.98-2.25-6.33-4.86-12.55-7.68-18.66-.82-1.77-2.53-3.69-4.28-4.37-5.96-2.3-12.07-4.24-18.22-5.99-2.88-.82-3.79-2.25-3.74-5.15.16-8.13.18-16.28,0-24.41-.07-3.09.83-4.64,3.96-5.51,5.99-1.66,11.93-3.58,17.7-5.88,1.88-.75,3.73-2.75,4.62-4.63,2.87-6.08,5.47-12.31,7.77-18.62.71-1.96.83-4.68.02-6.53-2.56-5.85-5.6-11.5-8.66-17.12-1.26-2.33-1.21-3.88.82-5.83,6.13-5.86,12.13-11.87,17.99-18.01,1.94-2.03,3.51-2.08,5.84-.82,5.63,3.04,11.29,6.05,17.15,8.61,1.85.81,4.57.75,6.51.05,6.48-2.34,12.86-5.02,19.13-7.88,1.64-.75,3.39-2.38,4.01-4.01,2.32-6.14,3.99-12.52,6.38-18.63.61-1.54,2.97-3.32,4.59-3.38,8.3-.34,16.62-.06,24.93-.21,2.88-.05,4.34.86,5.16,3.74,1.7,5.98,3.54,11.95,5.87,17.71.82,2.02,2.95,3.97,4.97,4.94,6.06,2.9,12.3,5.47,18.61,7.81,1.82.67,4.37.83,6.07.07,5.84-2.6,11.49-5.62,17.11-8.68,2.47-1.34,4.17-1.42,6.29.82,5.71,6.03,11.62,11.89,17.64,17.62,2.2,2.09,2.19,3.84.85,6.33-3.35,6.25-8.61,12.47-9.05,18.98-.41,6.12,5.12,12.41,6.65,18.94,1.49,6.37,4.95,9.16,10.86,10.53,4.7,1.09,9.29,2.75,13.8,4.48,1.33.51,3.14,2.08,3.18,3.23.3,7.43.18,14.88.16,22.33,0,1.34-.21,2.68-.39,4.69Zm-59.44-12.96c.04-33.48-27.24-60.83-60.69-60.84-33.34,0-60.81,27.52-60.74,60.87.07,33.14,27.37,60.46,60.52,60.55,33.49.09,60.87-27.14,60.91-60.58Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m392.81,79.57c-.24-4.76-.53-9.51-.7-14.27-.2-5.86,2.46-9.36,7.08-9.5,4.43-.13,7.29,3.01,7.69,8.56.98,13.46,2.01,26.92,2.86,40.38.53,8.37-3.11,11.46-11.39,9.75-13.2-2.72-26.39-5.54-39.56-8.4-5.61-1.22-8.2-4.83-7.11-9.52,1-4.31,4.75-6.19,10.26-5.09,7.17,1.44,14.32,2.92,22.15,3.27-1.83-2.21-3.6-4.47-5.49-6.63C302.76,1.52,164.25,11.36,101.16,107.83c-.76,1.16-1.46,2.36-2.28,3.47-2.8,3.76-6.9,4.79-10.39,2.66-3.7-2.25-4.95-6.83-1.95-10.79,8.54-11.29,16.56-23.19,26.44-33.21C189.41-7.46,315.16-4.36,388.04,76.35c1.15,1.27,2.16,2.67,3.23,4.01.51-.26,1.02-.53,1.53-.79Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m110.13,313.25c9.46,8.98,18.41,18.59,28.49,26.81,22.95,18.73,49.31,30.42,78.56,35.45,1.36.23,2.72.52,4.07.84,4.55,1.1,7.21,4.58,6.54,8.51-.69,4.06-4.22,7-8.8,6.05-10.81-2.24-21.75-4.33-32.21-7.78-31.71-10.49-58.66-28.48-80.93-53.41-.8-.9-1.38-1.99-2.06-3-.47.21-.95.41-1.42.62,0,3.26-.04,6.52.02,9.78.04,1.9.33,3.8.35,5.7.05,4.88-2.98,8.36-7.25,8.45-4.02.08-7.27-2.99-7.63-7.62-.8-10.18-1.42-20.37-2.12-30.56-.26-3.8-.55-7.6-.8-11.39-.46-7.09,3.15-10.73,10-9.38,13.91,2.74,27.77,5.75,41.61,8.83,5.32,1.19,7.64,4.88,6.47,9.4-1.08,4.16-4.84,6.17-9.83,5.15-7.21-1.48-14.41-3.03-21.62-4.54-.48.7-.96,1.4-1.44,2.1Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m149.57,446.62c21.97,0,43.95,0,65.92,0,1.73,0,3.48-.06,5.18.17,3.96.54,6.23,2.91,6.41,6.91.2,4.29-2.2,6.77-6.2,7.7-1.32.31-2.75.16-4.14.16-44.47,0-88.94,0-133.4,0-1.38,0-2.82.14-4.14-.17-4.02-.95-6.37-3.45-6.15-7.74.21-4.01,2.48-6.35,6.45-6.88,1.7-.23,3.45-.16,5.18-.16,21.63,0,43.26,0,64.88,0Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m435.87,203.32c-10.92-10.87-21.58-21.49-32.23-32.1-1.35-1.34-2.77-2.63-3.99-4.08-2.87-3.42-3-7.07.15-10.25,3.14-3.16,7.11-3.34,10.24-.27,14.08,13.82,28.03,27.79,41.86,41.86,3.11,3.16,2.93,7.17-.14,10.28-13.73,13.93-27.55,27.77-41.5,41.49-3.34,3.28-7.8,2.9-10.67-.2-3-3.24-2.81-7.31.9-11.07,10.33-10.47,20.79-20.83,31.18-31.24,1.32-1.32,2.58-2.71,4.19-4.42Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m58.93,203.63c9.94,9.94,19.47,19.46,29,28.98,2.2,2.2,4.48,4.34,6.59,6.62,3.4,3.69,3.48,8.04.37,11.07-3.19,3.1-7.19,2.87-11.08-.64-.38-.35-.74-.73-1.11-1.09-12.73-12.72-25.48-25.43-38.17-38.19-5.1-5.13-5.1-8.61-.04-13.69,12.7-12.75,25.44-25.47,38.18-38.18,4.52-4.51,8.8-5.11,12.2-1.82,3.54,3.42,2.95,7.78-1.79,12.55-10.01,10.05-20.07,20.07-30.09,30.11-1.21,1.21-2.35,2.48-4.05,4.28Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m124.13,481.39c14,0,28.01-.07,42.01.03,7.04.05,11.27,6.19,7.54,11.21-1.4,1.89-4.53,3.52-6.88,3.54-28.52.24-57.05.2-85.58.12-5.27-.02-8.17-2.85-8.2-7.33-.03-4.62,3.1-7.51,8.58-7.54,14.18-.07,28.35-.02,42.53-.03Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m108.55,411.75c8.83,0,17.66-.03,26.48.01,5.9.03,9.13,2.78,9.07,7.56-.06,4.61-3.09,7.26-8.79,7.28-17.83.07-35.66.07-53.49,0-5.76-.02-8.73-2.61-8.8-7.26-.07-4.83,3.11-7.55,9.04-7.58,8.83-.05,17.66-.01,26.48-.01Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m195.81,426.62c-7.6,0-15.19.07-22.79-.02-5.6-.06-8.72-2.76-8.78-7.29-.06-4.73,3.22-7.53,9.07-7.55,15.02-.04,30.04-.05,45.06,0,5.55.02,8.68,2.73,8.74,7.32.07,4.7-2.89,7.43-8.52,7.51-7.59.11-15.19.03-22.79.03Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m211.34,496.26c-3.12,0-6.25.18-9.35-.04-4.47-.33-7.09-3.01-7.04-7.43.05-4.43,2.83-7.11,7.22-7.31,5.88-.27,11.78-.26,17.66,0,4.36.19,7.06,2.68,7.26,7.24.2,4.36-2.61,7.23-7.45,7.55-2.76.18-5.54.03-8.31.03,0-.01,0-.02,0-.04Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m238.41,215c9.08-10.58,17.58-20.57,26.21-30.45,1.44-1.65,3.4-3.97,5.12-3.97,2.74,0,6.76.96,7.88,2.89,1.29,2.21,1.13,6.72-.37,8.83-4.59,6.45-10.13,12.23-15.3,18.26-5.62,6.56-11.19,13.18-16.91,19.65-4.47,5.06-8.61,5.11-13.09.15-5.56-6.16-11.01-12.41-16.39-18.72-3.22-3.78-3.08-8.29.07-11.1,3.21-2.86,7.57-2.47,10.99,1.21,3.86,4.15,7.54,8.46,11.78,13.25Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default QATestingIcon
