import React from 'react'

const MobileDevelopmentIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m324.53,176.63c-.1-1.67-.37-4.02-.37-6.38-.03-26.09-.01-52.19-.03-78.28,0-6.85-.14-6.99-6.84-7-71.08-.01-142.16.04-213.23-.11-5.17-.01-6.72,1.46-6.71,6.69.14,104.37.1,208.74.11,313.11,0,6.57.03,6.58,6.98,6.58,70.56,0,141.13.01,211.69-.02,6.65,0,8.41,1.64,8.08-7.71-.04-1.13.41-2.28.51-2.75,7.81.43,15.24.97,22.68,1.19,3.2.09,4.67,1.09,4.66,4.48-.09,21.63.26,43.26-.18,64.88-.32,15.39-12.84,27.87-28.11,29.13-3.76.31-7.54.51-11.31.51-67.99.03-135.98.04-203.96-.02-4.96,0-10.03-.08-14.85-1.1-13.89-2.93-23.34-14.81-24.17-29.62-.08-1.37-.05-2.75-.05-4.12,0-140.08.02-280.15-.04-420.23,0-11.26,3.2-20.88,12.24-28.16,7.48-6.02,16.3-6.71,25.32-6.72,47.21-.01,94.43,0,141.64,0,22.49,0,44.98-.01,67.47,0,8.36,0,16.45,1.04,23.34,6.46,8.05,6.33,12.55,14.57,12.58,24.81.11,42.92,0,85.83.13,128.75.01,4.29-1.46,5.79-5.66,5.66-6.98-.22-13.98-.06-21.91-.06Zm-113.82,289.27c7.88,0,15.77.05,23.65-.02,5.5-.04,8.95-2.91,8.83-7.14-.12-4.04-3.46-6.79-8.64-6.81-15.94-.05-31.88-.06-47.81,0-5.4.02-8.12,2.58-8.04,7.14.08,4.36,2.69,6.73,7.85,6.79,8.05.09,16.11.02,24.16.03Zm-.13-410.95c6.33,0,12.65.11,18.98-.04,4.82-.11,7.83-3,7.53-7.17-.32-4.5-3.05-6.73-7.46-6.75-12.48-.06-24.96-.09-37.44,0-4.73.04-7.92,3.02-7.94,6.94-.02,3.95,3.15,6.89,7.86,6.99,6.15.12,12.31.03,18.46.02Z'
					style={{ fill: fill }}
				/>
				<path
					d='m402.76,367.93c-3.9-2.31-7.11-3.63-9.57-5.8-5.92-5.23-11.05-5.86-19.03-2.58-8.71,3.58-11.95,8.03-12.05,16.89-.1,8.71-3.61,11.47-12.28,11.52-4.29.03-8.59.15-12.87-.1-5.01-.29-8.92-2.77-9.08-8.03-.34-11.41-5.18-18.11-16.71-21.19-4.91-1.31-7.77-1.62-11.7,1.63-13.16,10.89-13.3,10.75-25.54-1.53-.97-.97-1.95-1.94-2.91-2.92-5.91-6.01-6.65-9.2-1.21-15.55,6.08-7.09,7.16-13.09,2.99-22.2-3.44-7.53-7.25-10.21-14.82-10.44-10.21-.3-12.62-3.16-12.63-13.18,0-3.95-.07-7.9.03-11.84.14-5.46,2.41-9.09,7.66-9.2,11.61-.26,17.97-5.37,21.26-16.68,1.48-5.1,1.7-8.06-1.68-12.13-10.69-12.88-10.53-13.01,1.15-24.72,10.57-10.6,11.18-10.87,22.99-1.88,4.89,3.72,9.15,5.68,14.45,1.4.26-.21.6-.41.92-.45,11.19-1.52,13.47-9.21,13.71-18.9.16-6.54,3.43-9.16,9.86-9.26,4.98-.07,9.96.02,14.93-.02,5.07-.04,8.05,2.58,9.15,7.34,1.22,5.32.71,11.91,3.76,15.67,2.91,3.59,9.63,3.89,14.31,6.32,3.64,1.89,6.25,1.74,9.18-1.06,2.47-2.36,5.26-4.42,8.04-6.42,4.46-3.22,7.97-3.1,12.05.65,3.91,3.6,7.62,7.42,11.3,11.27,3.43,3.59,3.67,7.62.92,11.72-2.39,3.56-5.16,6.89-7.28,10.6-.83,1.45-.95,3.84-.39,5.45,1.62,4.68,3.48,9.33,5.82,13.69.94,1.75,3.19,3.37,5.15,3.88,3.44.9,7.12.86,10.7,1.29,5.73.69,8.8,3.63,9.1,9.29.27,5.13.25,10.3.02,15.44-.25,5.49-2.89,9.24-8.42,9.28-11.68.07-17.49,5.53-20.62,16.53-1.43,5.01-1.82,8.04,1.63,12.16,10.67,12.76,10.55,13.06-1.3,24.72-3.29,3.24-7.05,6.01-10.96,9.31Zm-91.09-78.96c-.09,18.14,14.06,32.58,32,32.65,17.83.08,32.53-14.55,32.52-32.34-.01-17.77-14.19-32.04-31.96-32.19-18.39-.15-32.47,13.63-32.55,31.88Z'
					style={{ fill: fill }}
				/>
				<path
					d='m185.41,230.71c-6.05.03-9.58-5.37-7.22-11.64,3.67-9.78,7.66-19.44,11.52-29.15,10.56-26.58,21.13-53.16,31.72-79.72.69-1.74,1.39-3.55,2.49-5.04,2.35-3.17,5.75-3.75,9.23-2.47,3.61,1.33,5.21,4.32,4.99,8.14-.07,1.17-.46,2.36-.9,3.47-14.6,36.77-29.22,73.53-43.83,110.3-1.45,3.64-3.77,6.1-7.98,6.11Z'
					style={{ fill: fill }}
				/>
				<path
					d='m284.71,166.56c-8.22-7.99-15.46-15.01-22.68-22.06-1.6-1.56-3.23-3.09-4.64-4.81-3-3.65-2.83-8.1.26-11.15,3.21-3.16,7.48-3.44,11.1-.14,4.69,4.27,9.05,8.9,13.51,13.41,5.67,5.73,11.34,11.47,16.96,17.25,5.43,5.57,5.46,9.31-.07,14.94-9.49,9.67-19.05,19.29-28.64,28.86-4.72,4.71-9.4,5.11-13.02,1.33-3.48-3.63-3.11-8.18,1.42-12.66,8.17-8.07,16.49-15.99,25.79-24.98Z'
					style={{ fill: fill }}
				/>
				<path
					d='m167.57,133.66c-1.93,3.04-3.05,5.57-4.84,7.47-6.48,6.86-13.18,13.5-19.81,20.22-5.02,5.09-5.06,5.12.11,10.38,6.61,6.74,13.44,13.26,19.83,20.2,1.8,1.96,3.38,5.09,3.29,7.63-.22,6.17-8.09,9.6-12.65,5.28-11.44-10.86-22.46-22.17-33.36-33.57-2.92-3.05-2.56-7.23.43-10.31,10.64-10.95,21.32-21.88,32.38-32.4,1.76-1.67,5.91-2.25,8.32-1.44,2.41.82,4.02,4.01,6.31,6.53Z'
					style={{ fill: fill }}
				/>
			</svg>
		</>
	)
}

export default MobileDevelopmentIcon
