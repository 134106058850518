import React from 'react'

const ECommereceIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m8.6,78.28v-24.78c16.16,0,32.11-1.04,47.84.33,12.59,1.1,20.29,9.96,23.56,22.25,6.99,26.28,14.15,52.52,20.82,78.88,1.33,5.27,3.41,6.78,8.78,6.77,122.56-.16,245.11-.13,367.67-.12,14.47,0,23.94,6.9,25.88,19.49.62,4,.07,8.39-.87,12.38-15.4,65.36-30.92,130.7-46.48,196.02-3.18,13.33-11.45,20.03-25.09,20.04-89.66.07-179.33.07-268.99,0-13.64-.01-21.46-6.28-24.92-19.37-26.85-101.3-53.72-202.6-80.39-303.95-1.54-5.85-3.77-8.35-10.15-8.09-12.31.51-24.65.15-37.64.15Zm416.05,157.77h-7.65c-81.07,0-162.14,0-243.21,0-1.68,0-3.45-.29-4.99.18-1.08.33-2.58,1.71-2.61,2.66-.22,7.25-.12,14.51-.12,21.94h258.58v-24.77Zm-236.21,102.7h214.6v-24.17h-214.6v24.17Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m186.49,153.27v-23.68c5.93,0,11.52-.17,17.1.06,3.66.15,5.31-.9,6.59-4.74,2.56-7.69,5.94-15.16,9.52-22.45,1.56-3.17,1.69-5.14-1.06-7.52-4.4-3.81-8.54-7.92-12.85-11.96,1.17-1.56,1.58-2.27,2.14-2.83,8.83-8.91,17.67-17.81,27.25-27.44,4.47,4.64,8.74,8.84,12.73,13.3,2.19,2.45,3.94,2.57,6.97,1.12,7.7-3.69,15.77-6.58,23.51-10.2,1.73-.81,3.65-3.22,3.81-5.03.48-5.81.18-11.69.18-18.05h41.23c0,5.82.13,11.82-.05,17.81-.1,3.27,1.15,4.5,4.31,5.63,7.83,2.82,15.5,6.18,23,9.81,2.99,1.45,4.68,1.33,6.97-1.07,4.16-4.37,8.71-8.36,12.67-12.09,9.29,9.25,18.74,18.66,28.88,28.75-4.92,4.2-10.45,8.92-15.39,13.13,5.04,11.95,9.38,22.26,14.01,33.24h21.58v23.46h-61.58c-.23-18.3-6.68-33.84-21.43-45.23-11.24-8.68-24.1-12.1-38.25-11.24-28.08,1.7-47.14,23.25-50.09,57.25h-61.73Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m173.15,449.82c.04-15.39,12.56-27.96,27.93-28.05,15.62-.09,28.51,12.7,28.44,28.25-.07,15.24-12.74,27.97-28.01,28.14-15.5.16-28.41-12.73-28.36-28.33Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m369.29,449.76c.07-15.38,12.62-27.93,27.99-27.99,15.62-.06,28.49,12.78,28.37,28.31-.11,15.26-12.81,27.96-28.07,28.07-15.51.12-28.37-12.79-28.3-28.4Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default ECommereceIcon
