import React from 'react'

const SoftwareDevelopmentIcon = ({height, fill}) => {
  return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
                height={height}
                fill={fill}
			>
				<path
					d='m288.97,7c8.7,3.95,11.85,10.95,10.63,20.07-.78,5.86.81,9.47,6.82,11.71,4.9,1.83,9.28,5.2,13.68,8.21,2.16,1.47,3.73,1.89,6,.28,2.76-1.95,5.8-3.52,8.8-5.11,7.51-4,15.56-2.14,19.85,5.12,9.95,16.86,19.72,33.83,29.31,50.9,4.02,7.16,1.72,14.99-5.17,19.51-3.9,2.56-9.46,4.07-11.45,7.63-1.87,3.35,0,8.79.15,13.31.06,1.78.34,3.69-.17,5.33-1.97,6.42.92,9.92,6.59,12.12,1.64.64,3.13,1.69,4.62,2.66,7.16,4.63,9.52,12.46,5.36,19.84-9.6,17.06-19.37,34.04-29.34,50.89-4.18,7.07-12.17,8.91-19.48,5.13-4.13-2.14-8.24-6.17-12.3-6.09-3.84.07-7.32,5.24-11.47,6.65-9.93,3.39-13.19,9.52-12.02,19.92.91,8.13-6.04,14.42-14.31,14.48-19.41.14-38.82.15-58.23,0-8.25-.06-15.21-6.38-14.3-14.5,1.17-10.39-1.94-16.82-12.06-19.82-2.09-.62-4.14-2.04-5.72-3.59-4.05-3.98-7.89-3.89-12.16-.42-1.73,1.41-3.85,2.38-5.87,3.39-7.37,3.71-15.32,1.85-19.48-5.18-9.96-16.86-19.73-33.83-29.33-50.89-4.14-7.35-2.13-16.31,5.51-19.57,9.73-4.15,13.27-10.26,10.97-20.37-.49-2.15-.49-4.67.15-6.76,1.83-5.98-1-8.95-6.06-11.02-1.93-.79-3.7-1.99-5.46-3.13-6.83-4.42-9.18-12.38-5.18-19.51,9.6-17.07,19.37-34.03,29.31-50.91,4.18-7.09,12.75-9.98,19.26-5.14,8.99,6.69,15.54,4.9,24.6-.54,8.29-4.99,12.91-9.29,11.43-19.42-1.1-7.56,4.28-11.98,10.49-15.19h66.01Zm-76.68,130.95c-.15,24.01,19.23,43.85,43.08,44.11,24.18.26,44.11-19.28,44.26-43.4.15-24.22-19.44-44.06-43.56-44.11-23.93-.05-43.63,19.48-43.78,43.4Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m252.08,505c-25.65-12.93-51.27-25.91-76.95-38.78-52.14-26.15-104.29-52.29-156.48-78.34-5.97-2.98-10.11-7.09-10.16-14.07-.06-7.22,4.35-11.23,10.33-14.51,4.82-2.64,8.63-2.75,13.75-.17,67.49,34.09,135.13,67.89,202.72,101.78,14.2,7.12,28.19,6.66,42.33-.45,61.77-31.04,123.61-61.95,185.42-92.92,6.79-3.4,13.52-6.93,20.4-10.12,1.48-.69,3.71-.65,5.28-.07,2.86,1.05,5.61,2.53,8.17,4.21,8.78,5.78,8.82,18.27.12,24.16-1.86,1.26-3.92,2.25-5.94,3.26-74.96,37.56-149.93,75.11-224.89,112.67-2.13,1.07-4.22,2.22-6.32,3.34h-7.77Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m97.51,184.07c12.08,20.98,23.48,41.66,35.75,61.82,9.65,15.86,28.6,22.83,46.91,18.54,1.08-.25,2.16-.51,3.51-.83.5,1.48.98,2.82,1.4,4.17,5.73,18.36,22.17,30.82,41.38,30.98,19.09.16,38.18.08,57.27.03,21.14-.05,37.15-11.95,43.41-32.15.28-.91.61-1.8.59-1.74,7.85,0,15.28.79,22.48-.18,11.95-1.61,21.77-7.91,28.02-18.19,11.26-18.52,21.79-37.49,32.56-56.31,1.11-1.94,1.79-4.11,2.74-6.34.76.19,1.4.24,1.93.51,26.3,13.16,52.62,26.28,78.86,39.56,9.33,4.72,12.12,14.75,6.15,22.21-1.93,2.42-4.84,4.3-7.66,5.72-41.41,20.86-82.89,41.6-124.35,62.37-34.09,17.08-68.24,34.05-102.24,51.32-6.97,3.54-13.07,3.72-20.16.15-75.64-38.09-151.37-75.98-227.12-113.85-5.92-2.96-10.25-6.91-10.45-13.9-.18-6.05,2.59-10.69,7.98-13.41,26.85-13.52,53.74-26.95,80.62-40.4.28-.14.63-.12.42-.09Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m503.52,305.56c-.34,6.76-3.88,11.06-9.74,13.98-22.13,11-44.2,22.12-66.3,33.2-53.72,26.92-107.49,53.75-161.13,80.85-7.17,3.62-13.35,3.72-20.58.08-75.47-38.01-151.04-75.81-226.62-113.6-5.19-2.59-9.29-5.86-10.5-11.83-1.12-5.52,1.44-11.49,6.33-14.76,6.28-4.19,11.8-5.54,19.73-1.49,66.78,34.05,133.89,67.44,200.91,101.01,14.03,7.03,27.91,6.46,41.88-.55,64.95-32.62,129.95-65.13,194.93-97.68,2.6-1.3,5.51-2.2,7.75-3.96,6.36-4.97,11.16-.32,16.15,2.38,4.78,2.59,6.91,7.06,7.18,12.38Z'
					style={{fill: '#c0ff00;'}}
				/>
			</svg>
		</>
	)
}

export default SoftwareDevelopmentIcon