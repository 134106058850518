import React from 'react'

const GraphicDesignIcon = ({height, fill}) => {
  return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
                height={height}
                fill={fill}
            
			>
				<path
					d='m490.6,93.82c-3.66,7.93-9.95,10.44-18.48,10.21-16.33-.44-32.68-.11-49.02-.14-10.48-.02-15.82-5.34-15.89-15.73-.02-3.78,0-7.56,0-11.54h-55.68c1.83,1.35,3.06,2.31,4.35,3.21,42.85,29.89,68.17,70.79,75.91,122.33,1.39,9.24,3.26,15.73,12.63,20.8,14.38,7.79,20.49,26.44,16.69,42.29-3.9,16.24-17.72,29.2-33.37,31.27-18.19,2.41-34.33-5.31-42.55-20.37-11.64-21.31-3.85-46.04,18.1-57.05,2.26-1.13,2.97-2.26,2.68-4.89-4.74-43.28-23.83-78.69-57.98-105.78-15.61-12.38-33.26-20.87-52.46-26.19-2.39-.66-3.48,0-4.76,1.98-16.99,26.17-52.57,26.23-69.41.11-1.42-2.2-2.62-2.74-5.14-1.97-58.58,18.05-94.36,57.12-107.76,116.81-1.07,4.75-1.28,9.68-2.26,14.46-.59,2.9.16,4.3,2.85,5.65,14.8,7.46,22.91,19.72,23.08,36.19.17,16.4-7.41,28.99-21.99,36.93-20.48,11.16-45.84,2.46-56.47-19.25-9.63-19.65-.4-45.08,20.05-54,4.03-1.76,4.93-4.14,5.3-7.81,4.43-44.95,22.85-83.19,55.56-114.32,7.71-7.33,16.45-13.58,24.7-20.32h-54.44c0,4.25.07,8.49-.01,12.72-.18,8.69-5.72,14.38-14.4,14.43-18.17.1-36.35.1-54.52,0-8.68-.05-14.36-5.72-14.41-14.39-.1-17.87-.1-35.74,0-53.61.05-8.68,5.72-14.36,14.4-14.41,18.17-.1,36.35-.1,54.52,0,8.68.05,14.23,5.73,14.41,14.41.09,4.11.01,8.23.01,13.05h4.99c34.21,0,68.42-.05,102.62.08,3.45.01,5.24-.83,6.88-4.16,7.4-15.04,19.84-23.17,36.52-23.24,16.88-.07,29.4,8.13,36.95,23.3,1.5,3.02,3.02,4.12,6.45,4.1,34.36-.15,68.72-.09,103.08-.09h4.9c0-4.51-.05-8.59.01-12.68.14-9.21,5.68-14.76,14.96-14.8,17.56-.07,35.13.15,52.68-.1,7.77-.11,12.45,3.72,15.72,10.16v62.32Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m172.63,490.67c-1.06-.55-2.13-1.09-3.19-1.66-6.4-3.43-8.88-9.87-6.42-16.65,8.16-22.44,28.11-37.22,51.81-37.51,27.45-.34,54.92-.34,82.37,0,23.7.29,43.65,15.06,51.81,37.51,2.86,7.86-.02,13.35-9.61,18.31h-166.77Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m327.66,413.08c-2.19-.71-4.42-1.78-6.76-2.15-8.55-1.35-17.13-3.34-25.73-3.5-25.34-.46-50.69-.08-76.03-.21-11.1-.06-21.9,1.26-32.35,5.11-.82.3-1.73.37-2.2.47-3.32-10.83-6.16-21.58-9.91-32.01-7.53-20.93-19.18-39.43-34.33-55.73-.83-.89-1.68-1.78-2.5-2.68-6.78-7.49-7.23-12.93-1.63-21.34,15.47-23.25,30.97-46.47,46.46-69.71,18.62-27.93,37.24-55.87,55.86-83.8.93-1.39,1.89-2.76,3.32-4.85.19,1.77.4,2.83.4,3.89.02,40.62-.01,81.24.08,121.86,0,2.81-.74,4.24-3.42,5.46-17.49,8.04-26.31,25.16-23.31,44.75,2.48,16.22,17.42,30.75,33.82,32.9,19.56,2.56,36.6-6.72,44.38-24.18,8.84-19.84-.04-43.88-19.89-53.05-3.33-1.54-4.25-3.3-4.23-6.82.13-39.86.08-79.71.08-119.57v-5.6c1.38,1.86,2.1,2.75,2.73,3.69,34.45,51.67,68.9,103.35,103.33,155.04,5.51,8.28,5.34,13.94-1.37,20.97-23.59,24.72-38.68,53.8-45.42,87.27-.18.9-.43,1.78-.7,2.66-.08.28-.3.51-.68,1.13Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m255.89,324.74c-7.48-.08-13.65-6.37-13.58-13.84.08-7.48,6.37-13.65,13.84-13.58,7.48.08,13.65,6.37,13.58,13.84-.08,7.48-6.37,13.65-13.84,13.58Z'
					style={{fill: '#c0ff00;'}}
				/>
			</svg>
		</>
	)
}

export default GraphicDesignIcon