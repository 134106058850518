import React from 'react'

const WebDevelopmentIcon = ({ height, fill }) => {
	return (
		<div>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m15.8,360.95c0-2.28,0-4.17,0-6.05,0-74.53,0-149.06,0-223.59,0-11.94,3.85-15.83,15.67-15.83,19.83-.01,39.65,0,59.48,0h5.16c-13.65,79.27,9.42,143.85,78.69,186.11,60.36,36.83,122.89,33.81,182.29-4.73,50.43-32.72,88.29-100.27,69.83-180.96.69-.14,1.37-.39,2.06-.39,18.11-.03,36.22-.11,54.33,0,8.13.05,12.85,4.73,12.86,12.85.05,77.01,0,154.01-.01,231.02,0,.37-.14.73-.31,1.56H15.8Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m15.8,385.26h479.93c0,8.76.58,17.47-.22,26.04-.47,5.07-4.67,8.12-10.08,8.54-1.9.15-3.81.11-5.72.11-149.1,0-298.19,0-447.29,0-13.31,0-16.63-3.29-16.63-16.44,0-5.88,0-11.75,0-18.25Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m314.13,144.58h-104.98c.35-5.46.59-10.87,1.06-16.25,2.59-29.93,7.69-59.36,20.8-86.66,4.22-8.78,9.91-17.23,16.47-24.42,9.11-9.98,19.19-9.47,28.99-.12,9.75,9.3,15.49,21.14,20.28,33.43,11.26,28.86,15.94,59.06,17.56,89.82.07,1.29-.11,2.59-.19,4.21Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m209.12,168.42h105.68c-.92,10.31-1.55,20.31-2.75,30.24-3.07,25.23-8.14,50.06-19.49,72.99-4.33,8.74-9.98,17.18-16.56,24.35-8.95,9.74-18.91,9.25-28.56.1-10.66-10.1-16.51-23.14-21.56-36.57-10.98-29.18-15.14-59.61-16.75-91.11Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m223.17,443.82h65.71c2.86,10.13,5.81,20.13,8.46,30.21.93,3.53,2.56,4.77,6.3,4.72,16.77-.21,33.54-.17,50.31-.04,7.87.06,12.66,4.12,13.16,10.61.49,6.34-3.63,11.62-9.91,12.58-1.5.23-3.04.16-4.56.16-64.41,0-128.82.01-193.23,0-8.53,0-13.54-3.48-14.42-9.89-1.07-7.82,4.11-13.37,13.08-13.46,15.82-.16,31.64-.38,47.44.11,6.07.19,8.66-1.58,9.97-7.52,2.04-9.23,5.05-18.25,7.69-27.48Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m216.34,18.53c-11.51,19.2-18.87,39.81-22.83,61.37-3.9,21.27-6.17,42.83-9.22,64.69h-67.47c-4.81-46.07,55.69-122.89,99.52-126.06Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m406.88,144.57h-68.66c-2.47-43.8-8.27-86.95-30.73-126.11,44.39,4.01,103.68,79.28,99.39,126.11Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m185.62,168.51c2.23,44.22,8.4,87.04,30.67,126.22-50.41-13.17-97.67-67.93-99.62-126.22h68.95Z'
					style={{ fill: '#c0ff00' }}
				/>
				<path
					d='m338.16,168.4h68.47c7.2,43.19-54.7,122.02-99.17,126.2,22.34-39.03,28.31-81.94,30.7-126.2Z'
					style={{ fill: '#c0ff00' }}
				/>
			</svg>
		</div>
	)
}

export default WebDevelopmentIcon