import React from 'react'

const MaintainSupportIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m235.23,151.44c5.55-13.33,10.64-25.54,16.22-38.94-3.44-3.3-7.91-7.46-12.23-11.77-4.3-4.29-4.82-7.2-.77-11.49,8.5-9.03,17.3-17.81,26.33-26.31,4.13-3.88,7.05-3.41,11.1.64,4.33,4.32,8.49,8.83,12.03,12.53,13.44-5.61,25.93-10.82,39.08-16.31,0-4.92-.03-10.88,0-16.83.04-6.29,1.63-8.72,7.89-8.94,12.23-.43,24.49-.4,36.71.05,5.92.22,7.61,2.76,7.66,8.67.05,5.96.01,11.91.01,17.13,12.95,5.37,25.43,10.55,39.14,16.23,3.32-3.51,7.44-8.04,11.74-12.37,4.19-4.22,7.16-4.67,11.51-.6,8.92,8.37,17.61,17.03,25.98,25.95,4.2,4.48,3.67,7.43-.84,11.9-4.22,4.2-8.61,8.23-12.09,11.54,5.57,13.35,10.71,25.69,16.22,38.91,4.91,0,11.02-.06,17.12.02,5.84.08,8.54,2.1,8.69,7.74.32,12.23.32,24.48,0,36.71-.15,5.78-2.69,7.73-8.53,7.83-6.11.1-12.23.02-17.34.02-5.43,13.12-10.54,25.49-16.31,39.43,3.28,3.01,7.74,6.91,11.98,11.04,4.85,4.73,5.34,7.41.84,12.21-8.37,8.92-17.1,17.52-26.02,25.89-4.03,3.78-7.19,3.26-11.16-.59-3.67-3.56-7.1-7.39-10.96-10.72-1.1-.95-3.61-1.27-5.02-.71-10.73,4.22-21.38,8.66-31.96,13.25-1.29.56-2.79,2.38-2.87,3.7-.33,5.26-.18,10.56-.13,15.84.04,4.89-2.18,8-7.17,8.06-12.74.16-25.49.16-38.23-.03-4.74-.07-6.91-3.07-6.87-7.76.04-5.28.23-10.59-.14-15.84-.1-1.44-1.73-3.39-3.14-4-10.57-4.62-21.22-9.04-31.94-13.3-1.3-.52-3.65-.27-4.64.61-3.95,3.49-7.57,7.37-11.29,11.12-3.44,3.46-7.29,4.03-10.83.6-9.14-8.88-18.15-17.9-27-27.06-3.28-3.4-2.67-7.11.69-10.37,4.51-4.37,9.01-8.75,12.45-12.09-4.96-13.01-9.41-24.91-14.14-36.7-.48-1.2-2.62-2.37-4.05-2.45-5.27-.3-10.56-.13-15.85-.14-4.72-.01-7.56-2.42-7.63-7.08-.19-12.57-.28-25.16.01-37.72.12-5.37,2.8-7.39,8.35-7.47,6.11-.09,12.22-.02,17.37-.02Zm191.6,26.39c-.12-41.05-32.96-73.94-73.73-73.83-40.92.11-73.85,33-73.87,73.77-.02,40.75,33.56,73.92,74.59,73.69,40.12-.23,73.12-33.51,73.01-73.63Z'
					style={{ fill: fill }}
				/>
				<path
					d='m83.77,417.38c-.08-1.46-.22-2.95-.22-4.45-.01-37.34.05-74.69-.1-112.03-.01-3.7,1.07-5.42,4.61-6.45,13.71-4.02,27.25-8.62,41.02-12.38,10.65-2.91,20.94-.99,30.52,4.61,39.26,22.96,78.57,45.84,117.78,68.88,9.34,5.49,13.13,13.69,11.15,22.58-2.21,9.9-8.7,16.51-18.48,16.86-4.8.17-10.23-1.32-14.46-3.69-23.34-13.05-46.4-26.6-69.56-39.98-1.18-.68-2.33-1.45-3.59-1.95-4.2-1.68-7.74-.71-10.26,3.07-2.42,3.64-1.59,8.24,2.21,10.82,3.79,2.58,7.89,4.71,11.87,7.01,20.8,12.01,41.67,23.9,62.37,36.09,8.73,5.14,17.43,6.13,27.4,3.87,36.37-8.24,72.86-15.88,109.33-23.68,24.81-5.3,49.63-10.59,74.48-15.68,13.96-2.86,26.34,4.93,29.36,18.03,2.93,12.72-4.84,24.84-18.61,28.44-57.31,15-114.68,29.81-171.96,44.9-15.63,4.12-31.2,8.6-46.53,13.7-9.65,3.21-19.02,3.24-27.89-.83-15.29-7.01-30.25-14.77-45.2-22.51-10.73-5.56-21.1-11.83-31.84-17.38-25.55-13.2-35.63-15.93-63.39-17.87Z'
					style={{ fill: fill }}
				/>
				<path
					d='m15.01,358.23c0-24.2-.01-48.4,0-72.61,0-9.19,3.9-13.21,12.91-13.29,8.86-.08,17.73-.09,26.59,0,8.46.1,12.6,4.21,12.6,12.6.04,49.09.04,98.17,0,147.26,0,8.34-4.25,12.47-12.68,12.53-9.03.07-18.07.08-27.1,0-8.07-.08-12.29-4.3-12.31-12.35-.05-24.71-.02-49.43-.02-74.14Zm25.89-69.91c-7.46.07-13.6,6.31-13.52,13.73.09,7.42,6.43,13.63,13.8,13.5,7.47-.13,13.61-6.39,13.55-13.79-.06-7.39-6.35-13.51-13.83-13.44Z'
					style={{ fill: fill }}
				/>
				<path
					d='m343.52,187.36c10.58-12.15,20.55-23.55,30.45-35,2.5-2.89,5.32-4.88,9.31-3.87,6.32,1.6,8.05,8.69,3.31,14.21-9.65,11.23-19.45,22.33-29.18,33.49-2.24,2.56-4.4,5.2-6.74,7.67-3.94,4.16-8.45,4.49-12.54.59-6.4-6.11-12.66-12.37-18.79-18.75-3.59-3.74-3.46-8.62-.11-11.84,3.32-3.2,8.08-3.13,11.85.54,4.14,4.03,8,8.33,12.44,12.98Z'
					style={{ fill: fill }}
				/>
			</svg>
		</>
	)
}

export default MaintainSupportIcon
