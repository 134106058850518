import React from 'react'

const ARIcon = ({ height, fill }) => {
	return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				height={height}
				fill={fill}
			>
				<path
					d='m16.61,495.4c0-19-.02-38.01,0-57.01.03-25.8,16.08-48.03,40.59-56.24,30.51-10.21,61.06-20.32,91.51-30.7,4.22-1.44,6.85-.98,10.11,2.26,19.92,19.82,43.67,33.1,71.29,38.32,46.51,8.79,86.75-4.77,121.08-36.54,4.79-4.43,8.32-5.42,14.44-3.3,29.23,10.12,58.65,19.69,87.99,29.48,22.69,7.58,36.02,23.43,41.03,46.59.19.88.49,1.73.74,2.6v64.53H16.61Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m255.95,116.42c39.1,0,78.2.03,117.3-.01,17.31-.02,30.63,7.05,38.07,22.81,2.75,5.83,3.97,12.83,4.16,19.35.54,18.37.3,36.76.16,55.14-.18,25.45-17.24,42.28-42.75,42.29-78.04.02-156.09.03-234.13,0-20.61,0-35.55-10.99-40.9-30.19-1.03-3.69-1.42-7.67-1.45-11.52-.14-18.69-.21-37.39-.03-56.08.19-20.13,11.26-35.16,29.81-40.29,4.42-1.22,9.23-1.42,13.87-1.43,38.63-.11,77.26-.06,115.9-.06Zm-79.91,40.14h-39.4v39.25h39.4v-39.25Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m365.64,86.29h-219.14c11.51-33.05,49.52-67.58,104.03-69.59,60.49-2.23,101.9,33.66,115.11,69.59Z'
					style={{ fill: '#c0ff00;' }}
				/>
				<path
					d='m162.01,286.05h187.86c-5.17,33.22-52.29,68.5-92.87,68.87-49.57.45-86.55-38.64-94.99-68.87Z'
					style={{ fill: '#c0ff00;' }}
				/>
			</svg>
		</>
	)
}

export default ARIcon
