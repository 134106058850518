import React from 'react'

const TrainingAndDocumentationIcon = ({height, fill}) => {
  return (
		<>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
                height={height}
                fill={fill}
			>
				<path
					d='m108.9,14.6h189.54c0,32.05-.01,64.11,0,96.16.01,27.48,17.9,45.27,45.51,45.28,30.17,0,60.34,0,90.51,0,1.68,0,3.35,0,5.42,0,0,2.05,0,3.59,0,5.12,0,97.26.02,194.53-.01,291.79,0,26.26-18.2,44.42-44.47,44.42-92.86.02-185.73.01-278.59,0-2.66,0-5.37-.02-7.98-.45-22.37-3.66-36.68-20.71-36.69-43.77-.02-96.79-.01-193.59-.01-290.38,0-35.04-.05-70.08.03-105.12.03-15.04,6.12-27.3,18.7-35.51,5.37-3.5,11.99-5.09,18.05-7.55Zm147.11,227.26c27.79,0,55.58-.03,83.37.02,6.39.01,11.35-2.32,14.19-8.18,4.66-9.62-2.32-20.08-13.5-20.11-29.05-.08-58.09-.03-87.14-.03-26.85,0-53.7.02-80.55-.02-6.21,0-11.02,2.34-13.85,7.96-4.9,9.7,2.17,20.3,13.63,20.33,27.95.07,55.9.02,83.84.02Zm.53,28.29c-27.95,0-55.9.04-83.84-.03-6.61-.02-11.67,2.47-14.45,8.53-4.34,9.47,2.7,19.72,13.62,19.76,27.01.09,54.01.03,81.02.03,28.89,0,57.78-.02,86.67.02,6.41,0,11.33-2.45,14.1-8.32,4.54-9.64-2.52-19.94-13.74-19.97-27.79-.07-55.58-.02-83.37-.02Zm-1.04,84.87c27.95,0,55.9-.03,83.84.02,6.38.01,11.36-2.29,14.21-8.14,4.68-9.61-2.27-20.1-13.44-20.14-25.75-.09-51.5-.03-77.25-.03-30.15,0-60.29.02-90.44-.02-6.2,0-11.03,2.31-13.87,7.92-4.93,9.7,2.12,20.34,13.57,20.37,27.79.07,55.58.02,83.37.02Zm-27.78,56.58c18.52,0,37.04.03,55.56-.01,6.81-.02,11.89-3.33,14.12-8.99,3.82-9.71-3.23-19.26-14.37-19.27-36.73-.04-73.46-.03-110.19,0-9.46,0-15.92,5.87-15.84,14.26.08,8.38,6.51,14.01,16.09,14.02,18.21.02,36.41,0,54.62,0Z'
					style={{fill: '#c0ff00;'}}
				/>
				<path
					d='m429.67,127.73c-29.42,0-59.4.09-89.37-.06-7.11-.04-13.34-5.73-13.4-12.72-.25-30.26-.11-60.52-.11-90.05,34.16,34.15,68.56,68.54,102.88,102.84Z'
					style={{fill: '#c0ff00;'}}
				/>
			</svg>
		</>
	)
}

export default TrainingAndDocumentationIcon